<template>
  <div id="header" class="pt-5 px-5 pb-5 mb-5 flex justify-between mx-auto relative z-20 items-center">
    <a v-if="this.$route.fullPath !== '/start'" @click="goBack()" class="icon-link icon-left cursor-pointer"><i class="fas fa-angle-left font-icons"></i>{{$t('back_button')}}</a>
    <LangNav />
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const LangNav = defineAsyncComponent(() => import('@/partials/LangNav'));
export default {
  name: "Header",
  data() {
    return {
    }
  },
  components: {
    LangNav
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
  #header {

    max-width: var(--section-width);
  }
</style>
